import { Controller } from '@hotwired/stimulus';
import { useMeta } from 'stimulus-use';

export default class extends Controller {
  static metaNames = ['trengoId', 'trengoOffsetY', 'botDetected'];
  static values = { id: String, trengoContactData: Object };

  trengoEvents = [
    { name: 'modal:open', action: (e) => this.hideTrengo(e) },
    { name: 'modal:close', action: (e) => this.showTrengo(e) },
    { name: 'overlay:open', action: (e) => this.hideTrengo(e) },
    { name: 'overlay:close', action: (e) => this.showTrengo(e) },
    { name: 'profile-menu:opened', action: (e) => this.hideTrengo(e) },
    { name: 'profile-menu:closed', action: (e) => this.showTrengo(e) },
    { name: 'mobile-nav:opened', action: (e) => this.hideTrengo(e) },
    { name: 'mobile-nav:closed', action: (e) => this.showTrengo(e) }
  ];

  connect() {
    this.trengoLoaded = false;
    this.showTrengo = this.showTrengo.bind(this);
    this.hideTrengo = this.hideTrengo.bind(this);

    useMeta(this);

    if (!this.botDetectedMeta) this.loadCookieHub();
  }

  loadCookieHub() {
    this.appendCookieHubScript();
  }

  appendCookieHubScript() {
    const cookiehubId = this.idValue;
    const environment = import.meta.env.VITE_APP_ENV;
    const chSrc =
      environment === 'production'
        ? `https://cookiehub.net/c2/${cookiehubId}.js`
        : `https://dash.cookiehub.com/dev/${cookiehubId}.js`;

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = chSrc;
    document.head.appendChild(script);

    script.onload = () => {
      this.initializeCookieHub();
    };

    script.onerror = (err) => {
      console.error('Error loading the CookieHub script:', err);
    };
  }

  initializeCookieHub() {
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('consent', 'default', {
      security_storage: 'granted',
      functionality_storage: 'denied',
      personalization_storage: 'denied',
      ad_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      analytics_storage: 'denied',
      wait_for_update: 500
    });
    let cpm = {
      onInitialise: (status) => {
        if (window.cookiehub.hasAnswered()) {
          this.setupTrengo();
        }
      },
      onStatusChange: () => {
        if (window.cookiehub.hasAnswered()) {
          this.setupTrengo();
        }
      }
    };
    window.cookiehub.load(cpm);
  }

  setupTrengo() {
    if (this.trengoLoaded) return;
    window.Trengo = window.Trengo || {};
    Object.assign(window.Trengo, {
      key: this.trengoIdMeta,
      render: false,
      contact_data: this.trengoContactDataValue,
      extraOffsetY: this.calculateOffsetY()
    });
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://static.widget.trengo.eu/embed.js';
    document.head.appendChild(script);
    window.Trengo.on_ready = function () {
      window.Trengo.Api.Widget.render();
    };

    this.trengoLoaded = true;
    this.registerEvents();
  }

  registerEvents() {
    this.trengoEvents.forEach(({ name, action }) => {
      document.addEventListener(name, action);
    });
  }

  unregisterEvents() {
    this.trengoEvents.forEach(({ name, action }) => {
      document.removeEventListener(name, action);
    });
  }

  calculateOffsetY() {
    return this.trengoOffsetYMeta || '6px';
  }

  disconnect() {
    this.removeScript();
  }

  removeScript() {
    const script = document.querySelector(
      `script[src^="https://cookiehub.net/c2/"], script[src^="https://dash.cookiehub.com/dev/"]`
    );
    if (script) {
      script.parentNode.removeChild(script);
    }
  }

  openChat(e) {
    e.preventDefault();
    if (window.Trengo) window.Trengo.Api.Widget.open('chat');
  }

  closeChat(e) {
    e.preventDefault();
    if (window.Trengo) window.Trengo.Api.Widget.close();
  }

  showTrengo(e) {
    if (!this.openElements) return;

    this.trengoElements.forEach((el) => {
      if (this.openElements.includes(el)) el.style.display = '';
    });
  }

  hideTrengo(e) {
    const elements = this.trengoElements;
    this.openElements = elements.filter((el) => el.style.display !== 'none');
    this.openElements.forEach((el) => (el.style.display = 'none'));
    if (window.Trengo.Api?.widget) window.Trengo.Api.Widget.close();
  }

  get trengoElements() {
    const selectors = [
      '.TrengoWidgetLauncher__iframe',
      '.TrengoWidgetBadge__iframe'
    ];
    return selectors
      .map((selector) => document.querySelector(selector))
      .filter((el) => el !== null);
  }
}
